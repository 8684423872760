<template>
  <main>
    <package-header-defected title="Applications" sub-title="12764 Smith"></package-header-defected>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">

          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-3" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <div class="d-flex justify-content-between align-items-end pb-2">

            <!-- Table toolbar -->
            <div>
              <div class="btn-toolbar sticky-top" v-show="checked > 0">
                <div class="btn-group">
                  <svg class="icon icon-corner-left-down"><use xlink:href="/icons/symbol-defs.svg#icon-corner-left-down"></use></svg>
                </div>

                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-md btn-secondary">Download</button>
                  <button type="button" class="btn btn-md btn-secondary">Print</button>
                  <button type="button" class="btn btn-md btn-danger">Delete</button>
                </div>
              </div>
            </div>

            <router-link class="btn btn-md px-3 btn-tertiary" to="/pages/add-applications">+ Add Application</router-link>
          </div>

          <!-- Package Management table -->
          <div class="sticky-table">
            <table class="table table-hover table-stacked table-select-row">
              <thead class="bg-medium">
                <tr>
                  <th scope="row">
                    <input type="checkbox" id="th">
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Document No.</th>
                  <th scope="col">Modified</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableItems">
                  <td scope="row">
                    <input type="checkbox" :id="index" @click="checkbox($event)">
                  </td>
                  <td data-header="Name">
                    <span class="td-content"><a href="#">{{ item.b }}</a></span>
                    <button class="d-block btn btn-tertiary btn-sm mt-1">Correct Application</button>
                  </td>
                  <td data-header="Document No.">
                    <span class="td-content">{{ item.c }}</span>
                  </td>
                  <td data-header="Application">
                    <span class="td-content">{{ item.f }}</span>
                  </td>
                  <td data-header="Modified">
                    <span class="td-content">{{ item.d }}</span>
                  </td>
                  <td data-header="Status" v-html="item.e"></td>
                  <td data-header="Actions">
                    <span class="td-content"><router-link to="/pages/electronic-instrument">View Instrument</router-link></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Table help -->
          <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#application-help" aria-expanded="false" aria-controls="application-help">
            <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
            How to use this table
          </button>
          <div id="application-help" class="collapse" aria-labelledby="headingOne" data-parent="#application-help">
            <div class="collapse-body">
              Collapse content
            </div>
          </div>

        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Package Management"></help-panel>
  </main>
</template>

<script>

import PackageHeaderDefected from '../../components/PackageHeaderDefected.vue'
import PackageNavigation from '../../components/PackageNavigation.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header-defected': PackageHeaderDefected,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      checked: 0,
      sequenced: false,
      tableItems: [
        {
          a:'1',
          b:'A1 release',
          c:'Release',
          d:'Sep 23 2017',
          e:'Pending',
          f:'CA748827',
        },
        {
          a:'2',
          b:'A2 release',
          c:'Release',
          d:'Sep 22 2017',
          e:'<span class="td-content">Defected <span class="badge badge-pill badge-danger">&nbsp;</span></span>',
          f:'CA748828',
        },
        {
          a:'3',
          b:'Mortgage Caso',
          c:'Create Mortgage',
          d:'Sep 21 2017',
          e:'Pending',
          f:'CA748835',
        }
      ]
    }
  },
  methods: {
    checkbox: function($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked ++
      } else {
        _this.checked --
      }
    }
  }
}
</script>

